<template>
  <v-card max-width="426" class="mx-auto px-3 px-md-6 py-6">
    <div class="text-center">
      <v-icon size="84" class="mb-5">$checkMarkCircle</v-icon>
    </div>

    <h1 class="font-medium-20 gray13--text text-center mb-2">
      {{ $t("auth.registerPage.succeedRegistration.accountCreated") }}
    </h1>

    <p class="gray7--text text-center mb-2">
      {{ $t("auth.registerPage.succeedRegistration.description") }}
    </p>

    <div class="text-center mb-8 gray13--text default-height">
      {{ countdownTimer }}
    </div>

    <v-btn color="primary" block depressed height="56" @click="goToLogin">
      <span class="font-semiBold-14">
        {{ $t("auth.registerPage.succeedRegistration.goToLogin") }}
      </span>
    </v-btn>
  </v-card>
</template>

<script>
import $countdown from "@/mixins/countdown";

export default {
  name: "succeed-registration",
  components: {},
  mixins: [$countdown],
  props: [],
  data() {
    return {
      redirectInterval: "",
      countdownTimer: "",
      isCounterExpired: false,
      clockInterval: "",
    };
  },
  computed: {},
  created() {
    this.$countdown(6000);
    this.autoRedirectToLogin();
  },
  destroyed() {
    clearInterval(this.redirectInterval);
  },
  methods: {
    autoRedirectToLogin() {
      this.redirectInterval = setInterval(() => {
        this.goToLogin();
      }, 6000);
    },
    goToLogin() {
      this.$router.replace("/auth/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "SucceedRegistration";
</style>
